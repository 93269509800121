import { Link } from "react-router-dom";
import { useState } from "react";
import { PacmanLoader } from "react-spinners";
import { CSSTransition } from "react-transition-group";

const Hamburger = function () {
  const [open, setOpen] = useState(false);

  return (
    <div className="menu-burger">
      <i
        className="fa fa-bars menu-burger-icon"
        onClick={() => setOpen(!open)}
      ></i>
      <CSSTransition in={open} timeout={300} classNames="myClass" unmountOnExit>
        <div className="menu-links-wrapper">
          <div className="pacman">
            <PacmanLoader color="#e45447" size={10} />
          </div>
          <Link className="menu-link" to="/">
            Home
          </Link>
          <Link className="menu-link" to="/work">
            Work
          </Link>
          <Link className="menu-link" to="/resume">
            Resume
          </Link>
          <Link className="menu-link" to="/testimonials">
            Testimonials
          </Link>
        </div>
      </CSSTransition>
    </div>
  );
};

export default Hamburger;
