import { Link } from "react-router-dom";
import React, { useEffect, useRef } from "react";

import { ScrollTrigger } from "gsap/all";
import { gsap } from "gsap";

// import "../../../index.css";

import desktop from "../../../images/desktop.png";
import humanOnChair from "../../../images/human-on-chair.png";
import table from "../../../images/table.png";
import bottomRight from "../../../images/bottom-right.png";
import bottomLeft from "../../../images/bottom-left.png";
import topRight from "../../../images/top-right.png";
import topLeft from "../../../images/top-left.png";
gsap.registerPlugin(ScrollTrigger);

export default function WhatIDO() {
  const section = useRef(null);
  const leftSide = useRef(null);
  const images = useRef([]);

  const scrollToTop = () => {
    window.scrollTo(0, 0);
  };

  useEffect(() => {
    const sectionId = section.current;
    const leftSideClass = leftSide.current;

    gsap.fromTo(
      leftSideClass,
      {
        opacity: 0,
        x: -50,
      },
      {
        opacity: 1,
        x: 0,
        duration: 2,
        scrollTrigger: {
          trigger: sectionId,
          scrub: 1,
          start: "top center",
          end: "75% center",
        },
      }
    );

    gsap.fromTo(
      images.current[0],
      {
        scale: 0.7,
      },
      {
        scale: 1,
        duration: 2,
        scrollTrigger: {
          trigger: sectionId,
          scrub: 1,
          start: "top center",
          end: "75% center",
        },
      }
    );
    gsap.fromTo(
      images.current[1],
      {
        opacity: 0,
        x: 50,
      },
      {
        opacity: 1,
        x: 0,
        duration: 2,
        scrollTrigger: {
          trigger: sectionId,
          scrub: 1,
          start: "top center",
          end: "75% center",
        },
      }
    );
    gsap.fromTo(
      images.current[2],
      {
        scale: 0.7,
      },
      {
        scale: 1,
        duration: 2,
        scrollTrigger: {
          trigger: sectionId,
          scrub: 1,
          start: "top center",
          end: "75% center",
        },
      }
    );
    gsap.fromTo(
      images.current[3],
      {
        opacity: 0,
        x: -50,
      },
      {
        opacity: 1,
        x: 0,
        duration: 2,
        scrollTrigger: {
          trigger: sectionId,
          scrub: 1,
          start: "top center",
          end: "75% center",
        },
      }
    );
    gsap.fromTo(
      images.current[4],
      {
        opacity: 0,
        y: 50,
      },
      {
        opacity: 1,
        y: 0,
        duration: 2,
        scrollTrigger: {
          trigger: sectionId,
          scrub: 1,
          start: "top center",
          end: "75% center",
        },
      }
    );
    gsap.fromTo(
      images.current[5],
      {
        opacity: 0,
        y: -50,
      },
      {
        opacity: 1,
        y: 0,
        duration: 2,
        scrollTrigger: {
          trigger: sectionId,
          scrub: 1,
          start: "top center",
          end: "75% center",
        },
      }
    );
    gsap.fromTo(
      images.current[6],
      {
        opacity: 0,
        y: -50,
      },
      {
        opacity: 1,
        y: 0,
        duration: 2,
        scrollTrigger: {
          trigger: sectionId,
          scrub: 1,
          start: "top center",
          end: "75% center",
        },
      }
    );
    // SetUp Animations End
  }, []);

  return (
    <section id="what-i-do" ref={section}>
      <div className="intro-what-i-do">
        <h1>What I Can Do</h1>
        <p>Service Offers</p>
        <div className="divider"></div>
      </div>
      <div className="main-what-i-do">
        {/* Left */}
        <div className="left-what-i-do" ref={leftSide}>
          <h2>FRONTEND APPS</h2>
          <p>
            {" "}
            I have personal experience in React for building dynamic web
            applications. I excel in utilizing CSS or other JavaScript/React
            libraries like "GSAP, Motion Framer, Scroll Magic" to create
            animations, scroll effects, and visually captivating designs. I stay
            updated with the latest trends to deliver high-quality frontend
            applications.
          </p>
          <h2>CMS WEBSITES</h2>
          <p>
            With WordPress and Divi, I can efficiently create visually appealing
            websites with powerful functionality and user-friendly interfaces.
          </p>
          <h2>NATIVE APPS</h2>
          <p>
            I have some experience with React Native, but nothing at a
            professional level yet. I am determined to master it at some point
            during my journey.
          </p>
          <Link to="/work" onClick={scrollToTop}>
            <div className="see-work">SEE MY WORKS</div>
          </Link>
        </div>
        {/* Right */}
        <div className="right-what-i-do">
          <img
            src={humanOnChair}
            className="images-right-side image-1"
            alt="human-on-chair"
            ref={(e) => (images.current[0] = e)}
          />
          <img
            src={desktop}
            className="images-right-side image-2"
            alt="desktop"
            ref={(e) => (images.current[1] = e)}
          />
          <img
            src={table}
            className="images-right-side image-3"
            alt="table"
            ref={(e) => (images.current[2] = e)}
          />
          <img
            src={bottomRight}
            className="images-right-side image-4"
            alt="task-1"
            ref={(e) => (images.current[3] = e)}
          />
          <img
            src={bottomLeft}
            className="images-right-side image-5"
            alt="task-2"
            ref={(e) => (images.current[4] = e)}
          />
          <img
            src={topRight}
            className="images-right-side image-6"
            alt="task-3"
            ref={(e) => (images.current[5] = e)}
          />
          <img
            src={topLeft}
            className="images-right-side image-7"
            alt="task-4"
            ref={(e) => (images.current[6] = e)}
          />
        </div>
      </div>
    </section>
  );
}
