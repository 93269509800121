import { Link } from "react-router-dom";

import logo2 from "../../images/logo-2.PNG";

export default function Footer() {
  return (
    <>
      <footer className="footer">
        {/* Logo */}
        <div className="footer-logo">
          <a className="logo" href="/">
            <img src={logo2} alt="logo" style={{ width: "30px" }} />
          </a>
        </div>
        {/* Links */}
        <div className="footer-links">
          <div className="nav-links">
            <ul>
              <li>
                <Link className="text-link orange-hover" to="/work">
                  <span style={{ margin: 0 }}>Work</span>
                </Link>
              </li>
              <li>
                <Link className="text-link orange-hover" to="/resume">
                  <span style={{ margin: 0 }}>Resume</span>
                </Link>
              </li>
              <li>
                <Link className="text-link orange-hover" to="/testimonials">
                  <span style={{ margin: 0 }}>Testimonials</span>
                </Link>
              </li>
            </ul>
          </div>
        </div>
        {/* Rights Reserved */}
        <div className="rights-reserved">
          © 2023 andreas all rights reserved
        </div>
      </footer>
      <div className="end-here">
        <div className="end-here-1 end-box"></div>
        <div className="end-here-2 end-box"></div>
        <div className="end-here-3 end-box"></div>
        <div className="end-here-4 end-box"></div>
      </div>
    </>
  );
}
