import image from "../../../images/use-this.jpg";

import { ScrollTrigger } from "gsap/all";
import { gsap } from "gsap";
import { useEffect, useRef } from "react";
gsap.registerPlugin(ScrollTrigger);

export default function Bio() {
  const bio = useRef(null);

  useEffect(() => {
    gsap.fromTo(
      bio.current,
      {
        y: 300,
      },
      {
        y: 0,
        duration: 2,
        scrollTrigger: {
          trigger: bio.current,
          scrub: 1,
          start: "-60% center",
          end: "-5% center",
        },
      }
    );
  }, []);

  return (
    <section id="bio" ref={bio}>
      <div className="bio-wrapper link-text">
        {/* TOP SIDE */}
        <div className="bio-top">
          <div className="bio-top-left">
            <img src={image} />
          </div>

          <div className="bio-top-right">
            <p>
              <span>
                <i className="fa fa-coffee"></i>
              </span>{" "}
              They say coffee and code are the perfect match, and I couldn't
              agree more. Whether it's debugging with a latte or styling with a
              cappuccino, my trusty sidekick keeps me caffeinated and my code
              perky. Together, we conquer the digital realm, one sip at a time!
            </p>
            <br />
            <p>
              <span>
                <i className="fa fa-gamepad"></i>
              </span>{" "}
              Video games are my ultimate escape, and you will probably find me
              somewhere in{" "}
              <span style={{ fontWeight: "bold" }}>"The Last Of Us"</span>{" "}
              universe, deeply moved by this masterpiece.
            </p>
            <br />
            <p>
              <span>
                <i className="fa fa-pencil"></i>
              </span>{" "}
              Ah yes.. I'm also a Sketching Enjoyer
            </p>
            <br />
            <p>
              <span style={{ fontWeight: "bold" }}>Fun Fact:</span> I am really
              bad with photography 🤣.
            </p>
          </div>
        </div>
        {/* BOTTOM SIDE */}
        <div className="bio-bottom">
          <div className="read-me">
            <h2>README</h2>
            <div className="divider"></div>
          </div>
          <div className="read-me-paragraphs">
            <p>
              I'm a junior frontend developer with one year of professional
              experience in the field.
            </p>
            <p>
              My passion for creating visually appealing and user-friendly
              websites has led me to specialize in HTML, CSS, JavaScript and
              many other technologies.
            </p>
            <p>
              I thrive on turning design concepts into interactive and
              responsive web interfaces. Along with my expertise in front-end
              technologies,
            </p>
            <p>
              I also have hands-on experience with popular frameworks such as
              React and libraries like jQuery. I'm excited to showcase my work
              and skills through my portfolio, so let's dive in!
            </p>
            <p>
              Additionally, I have gained experience in the UI/UX industry,
              utilizing Figma to create UI mockups.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
}
