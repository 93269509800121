import { BrowserRouter as Router, Route, Routes, Link } from "react-router-dom";
import Home from "../pages/Home";
import Work from "../pages/Work";
import Resume from "../pages/Resume";
import Testimonials from "../pages/Testimonials";
import Navbar from "../navbar/Navbar";
import Footer from "./Footer";
import logo2 from "../../images/logo-2.PNG";

export default function Main() {
  return (
    <Router>
      <div className="main">
        <div className="side-main">
          <Link className="logo" to="/">
            <img src={logo2} alt="logo" style={{ width: "30px" }} />
          </Link>

          <div className="media">
            <a
              href="https://www.linkedin.com/in/andreaslamperis/"
              target="_blank"
            >
              <i className="fa fa-linkedin"></i>
            </a>
            <a href="https://github.com/andreaslamperis" target="_blank">
              <i className="fa fa-github"></i>
            </a>
            <a
              href="https://www.facebook.com/profile.php?id=100004735725863"
              target="_blank"
            >
              <i className="fa fa-facebook"></i>
            </a>
          </div>
        </div>
        <div className="main-main">
          <Navbar />
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/work" element={<Work />} />
            <Route path="/resume" element={<Resume />} />
            <Route path="/testimonials" element={<Testimonials />} />
          </Routes>
        </div>
      </div>
      <Footer />
    </Router>
  );
}
