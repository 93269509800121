import imagePortfolio from "../../../images/portfolio-image.png";
import React, { useEffect, useRef } from "react";
import gsap from "gsap";

export default function Header() {
  const cartoonTextRef = useRef(null);
  const spansRef = useRef([]);
  const jobRole = useRef(null);
  const summary = useRef(null);
  const contactButton = useRef(null);

  useEffect(() => {
    // Cartoon Text Animation
    gsap.fromTo(
      cartoonTextRef.current,
      {
        opacity: 0,
      },
      {
        duration: 0.5,
        opacity: 1,
        delay: 1,
      }
    );
    gsap.fromTo(
      cartoonTextRef.current,
      {
        scale: 0.8,
      },
      {
        duration: 0.5,
        scale: 1,
        ease: "bounce",
        delay: 1,
      }
    );

    // H1 spans
    gsap.fromTo(
      spansRef.current,
      {
        opacity: 0,
        x: "-100%",
        scale: 0.1,
      },
      {
        opacity: 1,
        x: 0,
        scale: 1,
        duration: 0.2,
        stagger: 0.15,
        ease: "power1.out",
        delay: 1.5,
      }
    );

    // Job Role
    gsap.fromTo(
      jobRole.current,
      {
        opacity: 0,
        y: 30,
      },
      {
        opacity: 1,
        y: 0,
        ease: "power1.out",
        duration: 0.5,
        delay: 4.5,
      }
    );

    // Summary
    gsap.fromTo(
      summary.current,
      {
        opacity: 0,
        y: 30,
      },
      {
        opacity: 1,
        y: 0,
        ease: "power1.out",
        duration: 0.5,
        delay: 4.7,
      }
    );

    // Contact Button
    gsap.fromTo(
      contactButton.current,
      {
        opacity: 0,
        y: 30,
      },
      {
        opacity: 1,
        y: 0,
        ease: "stepped",
        duration: 1.3,
        delay: 5.1,
      }
    );
  }, []);

  return (
    <header className="home">
      {/* Left Side */}
      <div className="home-left text-link">
        <div className="cartoon-text" ref={cartoonTextRef}>
          It's me
        </div>
        <h1 className="header-1">
          <span className="name-spans" ref={(el) => (spansRef.current[0] = el)}>
            A
          </span>
          <span className="name-spans" ref={(el) => (spansRef.current[1] = el)}>
            n
          </span>
          <span className="name-spans" ref={(el) => (spansRef.current[2] = el)}>
            d
          </span>
          <span className="name-spans" ref={(el) => (spansRef.current[3] = el)}>
            r
          </span>
          <span className="name-spans" ref={(el) => (spansRef.current[4] = el)}>
            e
          </span>
          <span className="name-spans" ref={(el) => (spansRef.current[5] = el)}>
            a
          </span>
          <span className="name-spans" ref={(el) => (spansRef.current[6] = el)}>
            s
          </span>
          <br />
          <span className="name-spans" ref={(el) => (spansRef.current[7] = el)}>
            L
          </span>
          <span className="name-spans" ref={(el) => (spansRef.current[8] = el)}>
            a
          </span>
          <span className="name-spans" ref={(el) => (spansRef.current[9] = el)}>
            m
          </span>
          <span
            className="name-spans"
            ref={(el) => (spansRef.current[10] = el)}
          >
            p
          </span>
          <span
            className="name-spans"
            ref={(el) => (spansRef.current[11] = el)}
          >
            e
          </span>
          <span
            className="name-spans"
            ref={(el) => (spansRef.current[12] = el)}
          >
            r
          </span>
          <span
            className="name-spans"
            ref={(el) => (spansRef.current[13] = el)}
          >
            i
          </span>
          <span
            className="name-spans"
            ref={(el) => (spansRef.current[14] = el)}
          >
            s
          </span>
        </h1>
        <p className="job-role" ref={jobRole}>
          Software Engineer
        </p>
        <p className="job-summary" ref={summary}>
          Passionate junior web developer from Greece, dedicated to coding and
          continuous learning. Skilled in web development, I strive to expand my
          knowledge and stay up-to-date with the latest technologies. Eager to
          contribute to innovative projects and create impactful online
          experiences.
        </p>
        <div className="talk-bubble" ref={contactButton}>
          <a href="mailto:andreaslamperis@gmail.com" target="_blank">
            <div className="lets-talk">
              <span className="lets">let's</span>
              <br />
              <span className="talk">talk</span>
            </div>
          </a>
        </div>
      </div>
      {/* Right Side */}
      <div className="home-right">
        <img src={imagePortfolio} className="portfolio-image"></img>
      </div>
    </header>
  );
}
