import PageTransition from "./PageTransition";
import LogoImages from "./resume-components/LogoImages";
import { useRef, useEffect } from "react";

// GSAP SCROLL
import { ScrollTrigger } from "gsap/all";
import { gsap } from "gsap";

// FRONTEND IMAGES IMPORT
import htmlLogo from "../../images/work-logos/html.PNG";
import cssStyle from "../../images/work-logos/css.png";
import sass from "../../images/work-logos/sass.png";
import javascript from "../../images/work-logos/javascript.png";
import react from "../../images/work-logos/react.png";
import gsapLogo from "../../images/work-logos/gsap.svg";
import jquery from "../../images/work-logos/jquery.png";
import scrollMagicLogo from "../../images/work-logos/scrollmagiclogo.png";

// CMS IMAGES IMPORT
import diviTheme from "../../images/work-logos/divi.jpeg";
import elementor from "../../images/work-logos/elementor.svg";
import woocommerce from "../../images/work-logos/woocommerce.png";
import wordpress from "../../images/work-logos/wordpress.avif";

// OTHER TOOLS I USE IMPORT
import blender from "../../images/work-logos/blender.png";
import express from "../../images/work-logos/express.png";
import figma from "../../images/work-logos/figma.png";
import github from "../../images/work-logos/github.avif";
import node from "../../images/work-logos/node.avif";
import npm from "../../images/work-logos/npm.avif";
import postman from "../../images/work-logos/postman.avif";
import typescript from "../../images/work-logos/typescript.png";
import zbrush from "../../images/work-logos/zbrush.png";

// CV DOWNLOAD
import cv from "../../data/Andreas Lamperis CV.pdf";
gsap.registerPlugin(ScrollTrigger);

export default function Resume() {
  const textsShow = useRef(null);

  useEffect(() => {
    gsap.fromTo(
      textsShow.current,
      {
        opacity: 0,
        x: -30,
      },
      {
        opacity: 1,
        x: 0,
        duration: 2,
        scrollTrigger: {
          trigger: ".resume-wrapper",
          scrub: 1,
          start: "-20% center",
          end: "15% center",
        },
      }
    );
  }, []);

  return (
    <PageTransition>
      <section id="resume-page">
        <h1 className="my-works-h1" style={{ fontSize: "1.5rem" }}>
          Tech I Use
        </h1>
        <div className="divider"></div>
        <div className="techs-i-use">
          {/* Frontend Apps */}
          <div className="techs-wrapper frontend-tech">
            <h2>
              These are, but not limited to, the tech I use for building
              client-side applications
            </h2>
            <LogoImages
              src={htmlLogo}
              href="https://developer.mozilla.org/en-US/docs/Web/HTML"
              alt="html"
            />
            <LogoImages
              src={cssStyle}
              href="https://developer.mozilla.org/en-US/docs/Web/CSS"
              alt="css"
            />
            <LogoImages src={sass} href="https://sass-lang.com/" alt="sass" />
            <LogoImages
              src={javascript}
              href="https://developer.mozilla.org/en-US/docs/Web/JavaScript"
              alt="javascript"
            />
            <LogoImages src={jquery} href="https://jquery.com/" alt="jquery" />
            <LogoImages
              src={react}
              href="https://react.dev/"
              alt="react-framework"
            />
            <LogoImages
              src={gsapLogo}
              href="https://greensock.com/gsap/"
              alt="gsap-library"
            />
            <LogoImages
              src={scrollMagicLogo}
              href="https://scrollmagic.io/"
              alt="scroll-magic-labrary"
            />
          </div>
          {/* CMS Tools */}
          <div className="techs-wrapper cms-tech">
            <h2>
              These are, but not limited to, the tech I use for building fast
              and responsive websites with CMS
            </h2>
            <LogoImages
              src={wordpress}
              href="https://wordpress.com/"
              alt="wordpress"
            />
            <LogoImages
              src={diviTheme}
              href="https://www.elegantthemes.com/gallery/divi/"
              alt="divi-theme"
            />
            <LogoImages
              src={elementor}
              href="https://elementor.com/"
              alt="elementor"
            />
            <LogoImages
              src={woocommerce}
              href="https://woocommerce.com/"
              alt="woo-commerce"
            />
          </div>
          {/* Other Tools */}
          <div className="techs-wrapper other-tools-tech">
            <h2>These are some additional tools I use</h2>
            <LogoImages
              src={typescript}
              href="https://www.typescriptlang.org/"
              alt="typescript"
            />
            <LogoImages
              src={postman}
              href="https://www.postman.com/"
              alt="postman"
            />
            <LogoImages src={npm} href="https://www.npmjs.com/" alt="npm" />
            <LogoImages src={node} href="https://nodejs.org/en" alt="node" />
            <LogoImages
              src={express}
              href="https://expressjs.com/"
              alt="express"
            />
            <LogoImages
              src={github}
              href="https://github.com/home"
              alt="github"
            />
            <LogoImages src={figma} href="https://www.figma.com/" alt="figma" />
          </div>
          <div className="techs-wrapper other-tools-tech">
            <h2>
              Have I mentioned my passion for 3D yet? Those are some softwares I
              often use when I want to create something different that is
              related to 3D. I also really want to dive into Three.js at some
              point in my journey
            </h2>
            <LogoImages
              src={blender}
              href="https://www.blender.org/"
              alt="blender"
            />
            <LogoImages
              src={zbrush}
              href="https://pixologic.com/zblanding/"
              alt="postman"
            />
          </div>
        </div>
        <h1 className="my-works-h1" style={{ fontSize: "1.5rem" }}>
          Experience
        </h1>
        <div className="divider"></div>
        <div className="resume-wrapper">
          {/* Begin */}
          <div ref={textsShow}>
            <div className="novibet job">
              <h2>
                <span className="custom-bullet"></span>Junior Frontend Developer
              </h2>
              <p>
                <span className="job-title">
                  <a href="https://www.novibet.gr/stoixima">Novibet</a>
                </span>{" "}
                - Athens, Greece 08/2023 - now
              </p>
              <ul>
                <li>Bullets are under construction</li>
              </ul>
            </div>

            <div className="lemco job">
              <h2 style={{ marginTop: "1.5rem" }}>
                <span className="custom-bullet"></span>Junior Software Engineer
              </h2>
              <p>
                <span className="job-title">
                  <a href="https://lemco.gr/">Lemco</a>
                </span>{" "}
                - Athens, Greece 01/2023 - 05/2023
              </p>
              <ul>
                <li>
                  Developed GrapesJS (a web-based open-source framework)
                  components using JavaScript, jQuery, HTML, and CSS to enhance
                  the user interface and experience of our project
                </li>
                <li>
                  Successfully implemented many APIs into our project and
                  utilized JavaScript to fetch and control data to be displayed
                  on the front-end, demonstrating proficiency in API integration
                  and data manipulation techniques.
                </li>
                <li>
                  Demonstrated strong problem-solving skills by debugging and
                  fixing errors in the codebase, resulting in improved
                  functionality and performance of our project.
                </li>
                <li>
                  Consistently met deadlines for tasks while ensuring a high
                  standard of code quality and accuracy.
                </li>
                <li>
                  Actively participated in team meetings and provided insights
                  and suggestions on how to improve the project's development
                  process and product features.
                </li>
              </ul>
            </div>

            <div className="hybrid-studio job">
              <h2 style={{ marginTop: "1.5rem" }}>
                <span className="custom-bullet"></span>Web Developer (Intern)
              </h2>
              <p>
                <span className="job-title">
                  <a href="https://hybridstudio.gr/">Hybrid Studio</a>
                </span>{" "}
                - Athens, Greece 09/2022 - 12/2022
              </p>
              <ul>
                <li>
                  Independently developed multiple website projects and landing
                  pages from ideation to deployment, showcasing my proficiency
                  in web development.
                </li>
                <li>
                  Collaborated effectively with UI/UX design team to ensure the
                  technical feasibility of design concepts, resulting in
                  websites that are both visually appealing and functional.
                </li>
                <li>
                  Conducted comprehensive website performance tests, identifying
                  and fixing issues that resulted in faster load times, better
                  user experience, and improved search engine optimization
                  (SEO).
                </li>
                <li>
                  Proactively improved existing websites by identifying areas
                  for enhancement and implementing new features that
                  significantly improved user engagement and conversion rates.
                </li>
              </ul>
            </div>

            <div className="icare job">
              <h2 style={{ marginTop: "1.5rem" }}>
                <span className="custom-bullet"></span>Web Developer / Eshop
                Manager (Intern)
              </h2>
              <p>
                <span className="job-title">
                  <a href="https://icare.eu/gr/">
                    iCare International S.à r.l.
                  </a>
                </span>{" "}
                - Thessaloniki, Greece 04/2022 - 08/2022
              </p>
              <ul>
                <li>
                  Demonstrated proficiency in managing products using
                  WooCommerce, a widely used e-commerce plugin for WordPress,
                  gaining practical experience in data entry.
                </li>
                <li>
                  Developed the mobile view of the website menu, improving the
                  user experience for mobile users.
                </li>
                <li>
                  Acquired the ability to edit and make pages responsive within
                  WordPress.
                </li>
                <li>
                  Effectively collaborated with my supervisor to complete
                  complex tasks, demonstrating my ability to work
                  collaboratively and effectively as part of a team.
                </li>
              </ul>
            </div>

            <div className="livelihood-jobs job">
              <h2 style={{ marginTop: "1.5rem" }}>
                <span className="custom-bullet"></span>Livelihood jobs
              </h2>
              <p>
                Restaurants, Coffee stores, Internet Cafe, Thessaloniki 2017 -
                2022
              </p>
              <ul>
                <li>
                  Most of my Soft Skills like Problem-Solving, Leadership,
                  Teamwork, Communication, Adaptability, Fast-Learning etc.
                  developed through these jobs.
                </li>
              </ul>
            </div>
            {/* End */}
          </div>
        </div>

        <div className="resume-preview-wrapper">
          <p>Feel free to keep my Resume if you want 👉</p>
          <a href={cv} download="Andreas Lamperis CV.pdf">
            <div className="resume-download">DOWNLOAD</div>
          </a>
        </div>
      </section>
    </PageTransition>
  );
}
