import PageTransition from "./PageTransition";
import TestimonialUI from "./testimonials-components/TestimonialUi";

// Testimonial Images
import manos from "../../images/testimonial-images/manos.jpg";
import niki from "../../images/testimonial-images/niki.jpg";
import annio from "../../images/testimonial-images/annio.jpg";
import panagiotis from "../../images/testimonial-images/panagiotis.jpg";

export default function Testimonials() {
  return (
    <PageTransition>
      <h1
        className="my-works-h1"
        style={{ fontSize: "1.5rem", color: "#58595b" }}
      >
        Testimonials
      </h1>
      <div className="divider"></div>
      <div className="testimonials">
        <TestimonialUI
          image={manos}
          paragraph="Andreas, an exceptional web developer, delivered multiple projects independently and collaborated effectively with design teams. He optimized website performance, enhanced user experience, and increased conversion rates. A valuable asset during his 6-month tenure."
          name="Manos Magkaniaris"
          role="CEO of Hybrid Studio"
          alt="manos-magkaniaris"
        />
        <TestimonialUI
          image={niki}
          paragraph="Andreas is a highly enjoyable person to work with, bringing forth great ideas, organization, and a thirst for learning. He consistently offers assistance whenever there is a problem, displaying punctuality with deadlines, and showcasing a genuine passion for his work. Andreas actively seeks opportunities to expand his knowledge and skills, continuously striving to learn new things within his field. PS: Giving him coffee helps things to go smoothly in every aspect."
          name="Nicki Vasileiadou"
          role="Project Manager at iCare"
          alt="nicki-vasileiadou"
        />
        <TestimonialUI
          image={annio}
          paragraph="Working with Andreas was an absolute pleasure, and I highly recommend his services to anyone seeking a reliable and talented professional. No challenge was too big for him, and he consistently exceeded my expectations with his exceptional problem-solving abilities.His expertise was evident in the innovative solutions he proposed and the meticulous attention to detail he exhibited throughout our collaboration.am confident that Andreas will continue to achieve great things and I look forward to the opportunity to work with him again in the future."
          name="Anna Karampataki"
          role="Manager at Battlenet Thermis"
          alt="anna-karampataki"
        />
        <TestimonialUI
          image={panagiotis}
          paragraph="Andreas is a fast learner and can swiftly adapt to every situation needed. He is resourceful and restless, focusing on continuously improving his skill set. One of his most significant attributes is his outgoing personality and solid values and ideals. In my view, he can become a valuable asset to any organization."
          name="Panagiotis Kordonias"
          role="Manager at OPAP"
          alt="panagiotis-kordonias"
        />
      </div>
    </PageTransition>
  );
}
